import React, { FC } from 'react';

import TemplateUser from '../../../../../templates/user';

const Reserve: FC = () => (
  <TemplateUser>
    Une demande reservation a été créée et en cours de traitement. Vous recevrez
    un mail qui vous confirmera cette réservation et vous informera des
    différents détails liés à cette réservation.
  </TemplateUser>
);

export default Reserve;
